import actions from './actions';

const initialState = {
  cashFlowData: null,
  cfLoading: false,

  error: null,
};

const { CASH_FLOW_BEGIN, CASH_FLOW_SUCCESS, CASH_FLOW_ERR } = actions;

const chartContentReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CASH_FLOW_BEGIN:
      return {
        ...state,
        cfLoading: true,
      };
    case CASH_FLOW_SUCCESS:
      return {
        ...state,
        cashFlowData: data,
        cfLoading: false,
      };
    case CASH_FLOW_ERR:
      return {
        ...state,
        error: err,
        cfLoading: false,
      };

    default:
      return state;
  }
};

export default chartContentReducer;
