import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DrawerStyle } from './style';
import { Button } from '../buttons/buttons';

const Drawer = props => {
  const { visible, width, title, placement, children, render, onClose } = props;
  const [state, setState] = useState({
    visible: visible,
    placement: placement || 'right',
  });

  useEffect(() => {
    setState({ ...state, visible });
  }, [props]);

  return (
    <>
      <DrawerStyle
        title={title}
        placement={state.placement}
        closable={false}
        onClose={onClose}
        visible={state.visible}
        getContainer={!render}
        style={{ position: !render ? 'fixed' : 'absolute' }}
        width={width}
      >
        {children}
      </DrawerStyle>
    </>
  );
};

Drawer.defaultProps = {
  btnText: 'Open',
  width: 320,
  onClose: () => {},
  onSubmit: () => {},
};

Drawer.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  placement: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node, PropTypes.array]),
  customPlacement: PropTypes.bool,
  render: PropTypes.bool,
  btnText: PropTypes.string,
  width: PropTypes.number,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export { Drawer };
