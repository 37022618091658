import { gql } from 'graphql-request';
import { graphql } from './dataService';

/**
 * Gym API
 */

export const getAllGyms = async ({ first = 10, offset = 0, orderBy = 'CREATED_AT_DESC' }) => {
  const query = gql`
    query getAllGyms {
      gyms(first: ${first}, offset: ${offset}, orderBy: ${orderBy}) {
        nodes {
          gymId: id
          gymName: name
          owner {
            ownerId: id
            firstName
            lastName
            profilePhoto
          }
          gymMembers {
            totalCount
          }
          geoLocation {
            x
            y
          }
          createdAt
          isActive
          isDeleted
        }
        totalCount
      }
    }
  `;

  let {
    gyms: { nodes: gyms, totalCount },
  } = await graphql(query);
  return { gyms, totalCount };
};

export const searchGymByName = async (searchText = '') => {
  const query = gql`
    query searchGymByName($searchText: String) {
      searchGym(search: $searchText) {
        nodes {
          gymId: id
          gymName: name
          owner {
            ownerId: id
            firstName
            lastName
            profilePhoto
          }
          gymMembers {
            totalCount
          }
          geoLocation {
            x
            y
          }
          createdAt
          isActive
          isDeleted
        }
        totalCount
      }
    }
  `;

  let {
    searchGym: { nodes: searchGym, totalCount },
  } = await graphql(query, { searchText: searchText });
  return { searchGym, totalCount };
};

/**
 * Post API
 */

export const getAllPosts = async ({ first = 10, offset = 0, orderBy = 'CREATED_AT_DESC' }) => {
  const query = gql`
    query getAllPosts {
      posts(first: ${first}, offset: ${offset}, orderBy: ${orderBy}) {
        totalCount
        nodes {
          id
          postType
          mediaType
          user {
            id
            firstName
            lastName
            profilePhoto
          }
          comments {
            totalCount
          }
          postLikes {
            totalCount
          }
          createdAt
          isActive
          isDeleted
        }
      }
    }
  `;

  let {
    posts: { nodes: posts, totalCount },
  } = await graphql(query);
  return { posts, totalCount };
};

/**
 * Reported Post API
 */

export const getAllReportedPosts = async ({ first = 10, offset = 0, orderBy = 'CREATED_AT_DESC' }) => {
  const query = gql`
    query getAllReportedPost {
      reporedPosts(first: ${first}, offset: ${offset}, orderBy: ${orderBy}) {
        nodes {
          reportedPost {
            comments {
              totalCount
            }
            mediaUrl
            mediaType
            createdAt
            isDeleted
            isActive
            id
            message
            postLikes {
              totalCount
            }
            user {
              firstName
              lastName
              username
              id
              profilePhoto
            }
          }
          user {
            firstName
            lastName
            username
            id
            profilePhoto
          }
        }
        totalCount
      }
    }
  `;

  let {
    reporedPosts: { nodes: reporedPosts, totalCount },
  } = await graphql(query);

  return { reporedPosts, totalCount };
};

/**
 * Get Post by User Id for profile view
 */

export const getPostByUserId = async userId => {
  const query = gql`
    query getPostByUserId($userId: UUID!) {
      posts(condition: { userId: $userId }, orderBy: CREATED_AT_DESC) {
        nodes {
          id
          caption
          locationName
          mediaType
          mediaUrl
          message
          postLikes {
            totalCount
          }
          postTagUsers {
            totalCount
          }
          postType
          reporedPostsByReportedPostId {
            totalCount
          }
          videoThumbUrl
          user {
            firstName
            lastName
            profilePhoto
            username
          }
          locationVicinity
          createdAt
          comments {
            totalCount
            nodes {
              message
              user {
                firstName
                username
                lastName
                profilePhoto
              }
            }
          }
        }
        totalCount
      }
    }
  `;

  let {
    posts: { nodes: posts, totalCount },
  } = await graphql(query, { userId });

  return { posts, totalCount };
};

/**
 * Get Post detail by post id
 */

export const getPostById = async postId => {
  const query = gql`
    query getPostById($postId: UUID!) {
      post(id: $postId) {
        comments {
          totalCount
          nodes {
            message
            isActive
            id
            user {
              firstName
              lastName
              username
              profilePhoto
            }
          }
        }
        mediaType
        mediaUrl
        message
        locationName
        locationVicinity
        id
        createdAt
        user {
          firstName
          lastName
          username
          profilePhoto
        }
        caption
        isActive
        postType
        videoThumbUrl
        postLikes {
          totalCount
        }
      }
    }
  `;

  let { post } = await graphql(query, { postId });

  return { post };
};

/**
 * Delete post by post id
 */
export const deletePostByPostId = async postId => {
  const query = gql`
    mutation deletePostByPostId($postId: UUID!) {
      updatePost(input: { patch: { isDeleted: true, isActive: false }, id: $postId }) {
        post {
          id
        }
      }
    }
  `;

  let { post } = await graphql(query, { postId });

  return { post };
};

/**
 * Get Followers by User ID for profile preview
 */

export const getFollowersByUserId = async userId => {
  const query = gql`
    query getFollwers($userId: UUID!) {
      followers(condition: { userId: $userId }) {
        nodes {
          followedBy {
            firstName
            lastName
            profilePhoto
            username
          }
        }
      }
    }
  `;

  let { followers } = await graphql(query, { userId });

  return { followers };
};

/**
 * Get Following users by User ID for profile preview
 */

export const getFollowingByUserId = async userId => {
  const query = gql`
    query getFollowing($userId: UUID!) {
      followers(condition: { followedById: $userId }) {
        nodes {
          user {
            firstName
            lastName
            profilePhoto
            username
          }
        }
      }
    }
  `;

  let { followers } = await graphql(query, { userId });

  return { followers };
};

/**
 * User API
 */

export const updateUserDetailsById = async (userId, userDetails) => {
  const query = gql`
    mutation updateUser($input: UpdateUserInput!) {
      updateUser(input: $input) {
        user {
          __typename
        }
      }
    }
  `;

  const {
    updateUser: { user },
  } = await graphql(query, { input: { id: userId, patch: userDetails } });
  return user;
};

export const updateLeaderboardPoint = async (id, updatedLeaderboardPoint) => {
  const query = gql`
    mutation updateLeaderboard($input: UpdateLeaderboardPointInput!) {
      updateLeaderboardPoint(input: $input) {
        leaderboardPoint {
          sweetCount
          likeCount
        }
      }
    }
  `;

  const {
    updateLeaderboardPoint: { leaderboardPoint },
  } = await graphql(query, { input: { id: id, patch: updatedLeaderboardPoint } });
  return updateLeaderboardPoint;
};

/**
 * Get User Profile
 */

export const getUserDetailsById = async userId => {
  const query = gql`
    query getUserDetailsById($userId: UUID!) {
      user(id: $userId) {
        id
        firstName
        lastName
        bio
        email
        username
        profilePhoto
        createdAt
        updatedAt
        isActive
        isDeleted
        followers {
          totalCount
        }
        followersByFollowedById {
          totalCount
        }
        role {
          role
          id
        }
        posts {
          totalCount
        }
        subscrptions(orderBy: CREATED_AT_DESC, last: 1) {
          nodes {
            id
            planExpireDate
            planName
            isDeleted
            createdAt
            isActive
            updatedAt
          }
        }
      }
    }
  `;

  const { user } = await graphql(query, { userId });
  return user;
};

export const getAllUsers = async ({ first = 10, offset = 0, orderBy = 'CREATED_AT_DESC' }) => {
  const query = gql`
    query getAllUsers {
      users(first: ${first}, offset: ${offset}, orderBy: ${orderBy}) {
        totalCount
        nodes {
          id
          profilePhoto
          firstName
          lastName
          email
          username
          createdAt
          isActive
          isDeleted
          posts {
            totalCount
          }
          stripeConnectId
          subscrptions {
            nodes {
              planName
            }
          }
        }
      }
    }
  `;

  let {
    users: { nodes: users, totalCount },
  } = await graphql(query);
  return { users, totalCount };
};

export const searchUserByUsername = async (searchText = '') => {
  const query = gql`
    query searchUser($username: String) {
      searchUser(search: $username) {
        totalCount
        nodes {
          id
          profilePhoto
          firstName
          lastName
          email
          username
          createdAt
          isActive
          isDeleted
          posts {
            totalCount
          }
          stripeConnectId
          subscrptions {
            nodes {
              planName
            }
          }
        }
      }
    }
  `;

  let {
    searchUser: { nodes: searchUser, totalCount },
  } = await graphql(query, { username: searchText });
  console.log('SearchUser: => ', searchUser);
  console.log('totalCount ==> ', totalCount);
  return { searchUser, totalCount };
};

/**
 * Leaderboard Table
 *
 */

export const getLeaderboard = async ({ first = 200, offset = 0, orderBy = 'CREATED_AT_DESC' }) => {
  const query = gql`
    query MyQuery {
      leaderboardPoints {
        totalCount
        nodes {
          user {
            firstName
            id
            lastName
            username
            profilePhoto
            stripeConnectId
            email
          }
          sweetCount
          likeCount
        }
      }
    }
  `;

  let {
    leaderboardPoints: { nodes: leaderboardPoints, totalCount },
  } = await graphql(query);

  return { leaderboardPoints, totalCount };
};

export const getLeaderboardByUserId = async userId => {
  const query = gql`
    query getLeaderboardByUser($userId: UUID!) {
      leaderboardPoints(condition: { userId: $userId }, orderBy: CREATED_AT_DESC, last: 1) {
        nodes {
          id
          likeCount
          sweetCount
          userId
        }
      }
    }
  `;

  let {
    leaderboardPoints: { nodes: leaderboardPoints },
  } = await graphql(query, { userId });

  return leaderboardPoints;
};

export const updateLeaderboardByUserId = async userId => {
  const query = gql`
    query MyQuery {
      leaderboardPoints {
        totalCount
        nodes {
          user {
            firstName
            id
            lastName
            username
            profilePhoto
            stripeConnectId
            email
          }
          sweetCount
          likeCount
        }
      }
    }
  `;

  let {
    leaderboardPoints: { nodes: leaderboardPoints, totalCount },
  } = await graphql(query);

  return { leaderboardPoints, totalCount };
};

export const addLeaderboardByUserId = async () => {};

/**
 * Get Payment configuration for reward point calculation
 */

export const getPaymentConfiguration = async () => {
  const query = gql`
    query getPaymentConfig {
      paymentConfigs {
        nodes {
          id
          amount
          checkingReward
          likesReward
        }
      }
    }
  `;

  let {
    paymentConfigs: { nodes: paymentConfigs, totalCount },
  } = await graphql(query);

  return { paymentConfigs, totalCount };
};

export const updatePaymentConfig = async (id, patch) => {
  const query = gql`
    mutation updatePaymentConfig($input: UpdatePaymentConfigInput!) {
      updatePaymentConfig(input: $input) {
        paymentConfig {
          id
          checkingReward
          amount
          likesReward
        }
      }
    }
  `;

  const {
    paymentConfigs: { paymentConfigs },
  } = await graphql(query, { input: { id, patch } });
  return paymentConfigs;
};
