import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spin, Image, Form, Input, Switch } from 'antd';

import { Button } from '../../components/buttons/buttons';
import Heading from '../../components/heading/heading';
import { getUserDetailsById, updateUserDetailsById } from '../../config/dataService/graphqlApi';
import { EditUserDetailWrapper } from '../../pages/styled';
import { getUser, updatePlan } from '../../config/dataService/user';

const EditPlan = props => {
  console.log('props ====>', props);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [planDetails, setPlanDetails] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  const { userId, onClose, fetchAllPlans } = props;

  const validateForm = async () => {
    try {
      const values = await form.validateFields();
      setIsFormValid(true);
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
      setIsFormValid(false);
    }
  };

  const getPlanDetails = async () => {
    setIsLoading(true);
    const plan = props.planDetail;
    console.log('Plan Detail ==>', plan);

    setPlanDetails(plan);
    setIsLoading(false);
    form.setFieldsValue(plan);
    validateForm();
  };

  useEffect(() => {
    getPlanDetails();
  }, [props]);

  const handleFormChange = async e => {
    e.persist();
    const name = e.target.name;
    const value = e.target.value;
    const updatedValue = { ...planDetails };
    updatedValue[name] = value;
    setPlanDetails(updatedValue);
    validateForm();
  };

  const handleFormSubmit = async () => {
    setIsLoading(true);

    const updatedPlan = {
      plan_id: planDetails._id,
      name: planDetails.name,
      amount: planDetails.amount,
    };

    updatedPlan.updatedAt = new Date().toISOString();
    console.log('handleFormSubmit => UpdatedUser ==> ', updatedPlan);
    let updatedPlans = await updatePlan(updatedPlan);
    console.log('updatedPlans => updatedPlans ==> ', updatedPlans.data);
    fetchAllPlans();
    setIsLoading(false);
    onClose(true);
  };

  if (!planDetails) {
    return <></>;
  }

  return (
    <>
      <Spin spinning={isLoading} size="large" tip="Loading...">
        <Form form={form} layout="vertical" onChange={handleFormChange} initialValues={planDetails}>
          <Input type="hidden" name="user_id" value={planDetails._id} />
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input plan name',
              },
            ]}
          >
            <Input type="text" name="name" placeholder="Name" value={planDetails.name} required />
          </Form.Item>

          <Form.Item
            label="Amount(€)"
            name="amount"
            rules={[
              {
                required: true,
                message: 'Please input plan amount',
              },
            ]}
          >
            <Input type="text" name="amount" placeholder="Plan Amount" value={planDetails.amount} />
          </Form.Item>
        </Form>
      </Spin>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }}
      >
        <Button
          style={{
            marginRight: 8,
          }}
          onClick={() => onClose(false)}
        >
          Cancel
        </Button>
        <Button type="submit" onClick={handleFormSubmit} type="primary" disabled={!isFormValid}>
          Update
        </Button>
      </div>
    </>
  );
};

EditPlan.propTypes = {
  planId: PropTypes.string,
  onClose: PropTypes.func,
};

export default EditPlan;
