import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Table, Form, Input, Tooltip } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { TableWrapper, TableStyleWrapper } from './styled';
import userActions from '../redux/user/actions';
import Heading from '../components/heading/heading';
import { Button } from '../components/buttons/buttons';
import { Cards } from '../components/cards/frame/cards-frame';

import { getPlanList } from '../config/dataService/user';
import EditPlan from '../container/plans/EditPlan';
import { Drawer } from '../components/drawer/drawer';

const { setUserCounts } = userActions;

const usersTableColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: 'Amount(€)',
    dataIndex: 'amount',
    key: 'amount',
    sorter: (a, b) => a.amount - b.amount,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    sorter: (a, b) => a.type.localeCompare(b.type),
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];

const DownloadRequestListTable = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [planTableData, setPlanTableData] = useState([]);
  const [plansArrary, setPlans] = useState([]);
  const [totalUserCount, setTotalUserCount] = useState(0);
  const [pageInfo, setPageInfo] = useState({ first: 10, offset: 0 });
  const [state, setState] = useState({
    visible: false,
    modalType: 'primary',
    checked: [],
    responsive: 0,
    collapsed: false,
  });

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showEditPlanDrawer, setEditPlanDrawer] = useState(false);

  const preparePlanData = requests => {
    return requests.map(request => {
      const { _id, amount, name, type } = request;

      return {
        key: _id,
        name,
        amount: amount,
        type,
        action: (
          <div className="table-actions">
            <>
              <Tooltip title="Edit Plans">
                <Button className="btn-icon" type="info" onClick={e => handleEditPlans(e, request)} shape="circle">
                  <FeatherIcon icon="edit" size={16} />
                </Button>
              </Tooltip>
            </>
          </div>
        ),
      };
    });
  };

  const fetchAllPlans = async () => {
    const response = await getPlanList(pageInfo);
    console.log('fetchAllPlans ==> response', response);
    const plans = response.data.data;
    console.log('fetchAllPlans ==> user', plans);

    //setTotalUserCount(users.length);
    setPlanTableData(preparePlanData(plans));
    setPlans(plans);
  };

  useEffect(() => {
    fetchAllPlans();
  }, [pageInfo]);

  const handleEditPlans = (event, plan) => {
    event.preventDefault();
    console.log('selectedPlanData', plan);
    setSelectedPlan(plan);
    setEditPlanDrawer(true);
  };

  const handlePlanEditClose = refetch => {
    setEditPlanDrawer(false);
    if (refetch) {
      console.log({ refetch });
      fetchAllPlans();
    }
    setSelectedPlan(null);
  };

  return (
    <Cards headless>
      <TableStyleWrapper>
        <TableWrapper className="table-responsive">
          <Table
            // rowSelection={rowSelection}
            dataSource={planTableData}
            columns={usersTableColumns}
            // pagination={{
            //   defaultPageSize: 10,
            //   total: totalUserCount,
            //   showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            // }}
            // onChange={handleUserListTableChange}
          />
        </TableWrapper>
      </TableStyleWrapper>
      <Drawer title="Edit Plans" visible={showEditPlanDrawer} onClose={handlePlanEditClose} width={600}>
        <EditPlan planDetail={selectedPlan} fetchAllPlans={fetchAllPlans} onClose={handlePlanEditClose} />
      </Drawer>
    </Cards>
  );
};

export default DownloadRequestListTable;
