import { combineReducers } from 'redux';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import { userReducer } from './user/reducers';
import Profile from './profile/reducers';
import chartContentReducer from './chartContent/reducers';
import Note from './note/reducers';
import galleryReducer from './gallary/reducers';

const rootReducers = combineReducers({
  user: userReducer,
  auth: authReducer,
  chartContent: chartContentReducer,
  ChangeLayoutMode,
  Profile,
  Note,
  gallery: galleryReducer,
});

export default rootReducers;
