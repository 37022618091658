const actions = {
  USER_COUNTS: 'USER_COUNTS',

  setUserCounts: totalUsers => {
    console.log('setUserCountsAction => ', totalUsers);
    return {
      type: actions.USER_COUNTS,
      totalUsers: totalUsers,
    };
  },
};

export default actions;
