import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { Button } from '../../components/buttons/buttons';
import { Drawer } from '../../components/drawer/drawer';

import { Row, Col } from 'antd';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Main } from '../styled';
import PlanList from '../../pages/PlanList';
import AddUser from '../../container/usersList/AddUser';

const Plans = () => {
  const userState = useSelector(state => state.user);
  console.log('Subscribed State => ', userState);

  /**
   * View Drawer State
   */
  const [showAddUserDrawer, setAddUserDrawer] = useState(false);

  const handleAddUser = event => {
    event.preventDefault();

    setAddUserDrawer(true);
  };

  const handleAddUserClose = refetch => {
    setAddUserDrawer(false);
    window.location.reload(false);
  };

  return (
    <>
      <PageHeader
        ghost
        title="Plans"

        // buttons={[
        //   <div key="1" className="page-header-actions">
        //     <Button onClick={e => handleAddUser(e)} size="small" type="primary">
        //       <FeatherIcon icon="plus" size={14} />
        //       Add New
        //     </Button>
        //   </div>,
        // ]}
      />
      <Main>
        <Row gutter={25}>
          <Col xs={24}>
            <PlanList />
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default Plans;
