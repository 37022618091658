import actions from './actions';

const { USER_COUNTS } = actions;

const initState = {
  totalUsers: 0,
};

const userReducer = (state = initState, action) => {
  console.log('UserReducer Called! State => ', state);
  console.log('UserReducer Called! Action => ', action);
  const { type, totalUsers, err } = action;
  console.log('data ==>', totalUsers);
  switch (type) {
    case USER_COUNTS:
      return {
        ...state,
        totalUsers: totalUsers.totalUsers,
      };
    default:
      return state;
  }
};

export { userReducer };
