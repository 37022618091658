import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import Plans from '../../container/plans';

const Dashboard = lazy(() => import('../../container/dashboard'));
const Users = lazy(() => import('../../container/usersList'));
const DownloadRequest = lazy(() => import('../../container/downloadRequests'));
const Posts = lazy(() => import('../../container/postList'));
const Leaderboard = lazy(() => import('../../container/leaderboardList'));
const ReportPost = lazy(() => import('../../container/reportPost'));
const Profile = lazy(() => import('../../container/profile/myProfile/Index'));
const ProfileChange = lazy(() => import('../../container/profile/myProfile/ProfileChange'));
const LeaderboardSettings = lazy(() => import('../../container/settings/LeaderboardSettings'));

const DashboardRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Dashboard} />
      <Route path={`${path}/users`} component={Users} />
      <Route path={`${path}/download-requests`} component={DownloadRequest} />
      <Route path={`${path}/posts`} component={Posts} />
      <Route path={`${path}/leader-board`} component={Leaderboard} />
      <Route path={`${path}/report-post`} component={ReportPost} />
      <Route path={`${path}/profile/:id`} component={Profile} />
      <Route path={`${path}/profile-change`} component={ProfileChange} />
      <Route path={`${path}/leaderboard_setting`} component={LeaderboardSettings} />
      <Route path={`${path}/plans`} component={Plans} />
    </Switch>
  );
};

export default DashboardRoutes;
