var md5 = require("md5");

exports.createPassword = (message) => {
  return md5(message);
};

exports.validatePassword = (password, encryptedPassword) => {
  let md5Hash = md5(password);
  return md5Hash == encryptedPassword;
};
