const actions = {
  CASH_FLOW_BEGIN: 'CASH_FLOW_BEGIN',
  CASH_FLOW_SUCCESS: 'CASH_FLOW_SUCCESS',
  CASH_FLOW_ERR: 'CASH_FLOW_ERR',

  cashFlowBegin: () => {
    return {
      type: actions.CASH_FLOW_BEGIN,
    };
  },

  cashFlowSuccess: data => {
    return {
      type: actions.CASH_FLOW_SUCCESS,
      data,
    };
  },

  cashFlowErr: err => {
    return {
      type: actions.CASH_FLOW_ERR,
      err,
    };
  },
};

export default actions;
