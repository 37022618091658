import React from 'react';
import { Row, Col } from 'antd';
import { Aside, Content } from './overview/style';
import Heading from '../../../components/heading/heading';

const AuthLayout = WraperContent => {
  return () => {
    return (
      <Row>
        <Col xxl={8} xl={9} lg={12} md={8} xs={24}>
          <Aside>
            <div className="auth-side-content">
              <img src={require('../../../static/img/auth/topShape.png')} alt="" className="topShape" />
              <img src={require('../../../static/img/auth/bottomShape.png')} alt="" className="bottomShape" />
              <Content>
                {/* <img style={{ width: '150px' }} src={require('../../../static/img/able_logo.png')} alt="" /> */}
                <br />
                <br />
                <Heading as="h1">
                  <h3
                    style={{
                      color: '#f4f5f7',
                      fontFamily: " 'Cinzel' serif",
                      fontWeight: '500',
                      fontSize: ' 20px',
                      lineHeight: '1.3',
                    }}
                  >
                    Admin{' '}
                    <span
                      style={{
                        background: '#f4f5f7',
                        color: '#000000',
                        display: 'inline-block',
                        lineHeight: ' 1.8',
                        padding: '0 16px',
                      }}
                    >
                      Panel
                    </span>
                  </h3>
                  {/* Track your gym activities */}
                </Heading>
                {/* <img
                  className="auth-content-figure"
                  src={require('../../../static/img/auth/Illustration.png')}
                  alt=""
                /> */}
              </Content>
            </div>
          </Aside>
        </Col>

        <Col xxl={16} xl={15} lg={12} md={16} xs={24}>
          <WraperContent />
        </Col>
      </Row>
    );
  };
};

export default AuthLayout;
