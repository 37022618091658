import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spin, Image, Form, Input, Switch } from 'antd';

import { Button } from '../../components/buttons/buttons';
import Heading from '../../components/heading/heading';
import { getUserDetailsById, updateUserDetailsById } from '../../config/dataService/graphqlApi';
import { EditUserDetailWrapper } from '../../pages/styled';
import { createUser } from '../../config/dataService/user';
import { createPassword } from '../../config/md5';

const EditUser = props => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  const { userId, onClose } = props;

  const validateForm = async () => {
    try {
      const values = await form.validateFields();
      setIsFormValid(true);
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
      setIsFormValid(false);
    }
  };

  const getUserDetails = async () => {
    setIsLoading(true);
    const user = await getUserDetailsById(userId);
    setUserDetails(user);
    setIsLoading(false);
    form.setFieldsValue(user);
    validateForm();
  };

  useEffect(() => {
    if (userId) {
      getUserDetails();
    }
  }, [props]);

  const handleFormChange = async e => {
    e.persist();
    const name = e.target.name;
    const value = e.target.value;
    const updatedValue = { ...userDetails };
    updatedValue[name] = value;
    setUserDetails(updatedValue);
    validateForm();
  };

  const handleFormSubmit = async () => {
    setIsLoading(true);

    let password = createPassword(userDetails.password);
    const updateUser = { ...userDetails, password: password };

    await createUser(updateUser);
    setIsLoading(false);
    onClose(true);
  };

  return (
    <>
      <Spin spinning={isLoading} size="large" tip="Loading...">
        <Form form={form} layout="vertical" onChange={handleFormChange} initialValues={userDetails}>
          <Form.Item
            label="First name"
            name="first_name"
            rules={[
              {
                required: true,
                message: 'Please input first name',
              },
            ]}
          >
            <Input type="text" name="first_name" placeholder="first name" value={userDetails.firstName} required />
          </Form.Item>

          <Form.Item label="Last name" name="last_name">
            <Input type="text" name="last_name" placeholder="last name" value={userDetails.lastName} />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input email address',
              },
            ]}
          >
            <Input type="email" name="email" placeholder="email address" value={userDetails.email} required />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input password',
              },
            ]}
          >
            <Input type="password" name="password" placeholder="Password" value={userDetails.username} required />
          </Form.Item>

          <Form.Item label="Wallet Balance" name="wallet_balance">
            <Input type="text" name="wallet_balance" placeholder="0.00" value={userDetails.lastName} />
          </Form.Item>

          <Form.Item label="Is Active?" name="isActive">
            <Switch
              checked={userDetails.isActive}
              checkedChildren="Active"
              unCheckedChildren="Deactive"
              onChange={() => {
                setUserDetails({ ...userDetails, isActive: !userDetails.isActive });
              }}
            />
          </Form.Item>
        </Form>
      </Spin>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }}
      >
        <Button
          style={{
            marginRight: 8,
          }}
          onClick={() => onClose(false)}
        >
          Cancel
        </Button>
        <Button type="submit" onClick={handleFormSubmit} type="primary" disabled={!isFormValid}>
          Update
        </Button>
      </div>
    </>
  );
};

EditUser.propTypes = {
  userId: PropTypes.string,
  onClose: PropTypes.func,
};

export default EditUser;
