/**
 * It's suggested to configure the RESTful endpoints in this file
 * so that there is only one source of truth, future update of endpoints
 * could be done from here without refactoring on multiple places throughout the app
 */
const V1_API = '/api';

const API = {
  admin: {
    updateAdmin: `${V1_API}/admin/update`,
    changePassword: `${V1_API}/admin/changePassword`,
  },
  user: {
    login: `${V1_API}/admin/login`,
    getUsers: `${V1_API}/users/get-users`,
    createUser: `${V1_API}/users/create`,
    getUser: `${V1_API}/users/get-user-by-id`,
    updateUser: `${V1_API}/users/update`,
    deleteUser: `${V1_API}/users/delete`,
    getPassword: `${V1_API}/users/p`, // get password
    creditOrDebitFunds: `${V1_API}/wallet/entry`,
    walletHistory: `${V1_API}/wallet/history-by-user`,
    transactionList: `${V1_API}/wallet/history-by-user`,
    downloadRequestList: `${V1_API}/users/download-request-list`,
  },
  plans: {
    plans: `${V1_API}/plans`,
    update: `${V1_API}/plans/update`,
  },
  dashboard: {
    getCounts: `${V1_API}/dashboard/getCounts`,
  },
};

export { API };
